/* eslint-disable @typescript-eslint/naming-convention */
export interface FilterSetting {
    id?: number;
    userId: number;
    creationDate: string;
    displayName: string;
    settings: string;
    settingType: FILTER_SETTING_TYPE;
}

export enum FILTER_SETTING_TYPE {
    COUNTER_OVERVIEW_FILTER_SETTING = 'CounterOverview',
    EXPORT_ACTIVITIES_FILTER_SETTING = 'ExportActivities',
    SCHEDULE_OVERVIEW_V2_FILTER_SETTING = 'ScheduleOverviewV2',
}
