import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OmrpAuthGuard } from 'src/app/shared/guards/omrp-authentication.guard';

import { CountersExportComponent } from './counters-export/counters-export.component';
import { CountersOverviewComponent } from './counters/counters-overview.component';

import { OverviewsComponent } from './overviews.component';
import { ScheduleOverviewComponent } from './schedule/schedule-overview.component';

export const overviewsRoutes: Routes = [
    {
        path: 'overviews',
        canActivate: [OmrpAuthGuard],
        children: [
            {
                path: '',
                component: OverviewsComponent,
                data: {
                    breadcrumbs: [
                        {
                            caption: 'Overviews',
                        },
                    ],
                },
            },
            {
                path: 'schedule-overview',
                component: ScheduleOverviewComponent,
                data: {
                    breadcrumbs: [
                        {
                            caption: 'Schedule Overview'
                        },
                    ],
                },
            },
            {
                path: 'counters-overview',
                children: [
                    {
                        path: '',
                        component: CountersOverviewComponent,
                        data: {
                            breadcrumbs: [
                                {
                                    route: '/omrp#!/overviews',
                                    caption: 'Overviews',
                                },
                                {
                                    caption: 'Counters Overview'
                                },
                            ],
                        },
                    },
                    {
                        path: 'export',
                        component: CountersExportComponent,
                        data: {
                            breadcrumbs: [
                                {
                                    route: '/omrp#!/overviews',
                                    caption: 'Overviews',
                                },
                                {
                                    route: '/overviews/counters-overview',
                                    caption: 'Counters Overview',
                                },
                                {
                                    caption: 'Export'
                                },
                            ],
                        },
                    }
                ],
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(overviewsRoutes)],
    exports: [RouterModule]
})
export class OverviewsRoutingModule { }
