import { CommonModule, DecimalPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injector, NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createCustomElement } from '@angular/elements';
import { BryntumSchedulerModule } from '@bryntum/scheduler-angular'
import { BolNotificationModule } from '@ortec/bolster/notification';
import { BolCalloutModule } from '@ortec/bolster/callout';

import { SharedModule } from 'src/app/shared/shared.module';
import {
    CountersExportFiltersSettingsComponent,
} from './counters-export/components/counters-export-filters-settings/counters-export-filters-settings.component';
import { CountersExportComponent } from './counters-export/counters-export.component';
import { CountersFilterSettingsComponent } from './counters/components/counters-filter-settings/counters-filter-settings.component';
import { CountersFiltersHeaderComponent } from './counters/components/counters-filters-header/counters-filters-header.component';
import { CountersFiltersComponent } from './counters/components/counters-filters/counters-filters.component';
import { CountersMatrixComponent } from './counters/components/counters-matrix/counters-matrix.component';
import { CountersOverviewComponent } from './counters/counters-overview.component';

import { OverviewsRoutingModule } from './overviews-routing.module';
import { OverviewsComponent } from './overviews.component';
import { ScheduleCalendarComponent } from './schedule/components/schedule-calendar/schedule-calendar.component';
import { ScheduleFiltersHeaderComponent } from './schedule/components/schedule-filters-header/schedule-filters-header.component';
import { ScheduleFiltersSettingsComponent } from './schedule/components/schedule-filters-settings/schedule-filters-settings.component';
import { ScheduleFiltersComponent } from './schedule/components/schedule-filters/schedule-filters.component';
import { ShowOptionsConfigurationDialogComponent } from './schedule/components/schedule-filters/show-options-configuration-dialog/show-options-configuration-dialog.component';
import { ScheduleOverviewComponent } from './schedule/schedule-overview.component';
import { SCHEDULE_EVENT_TAG, ScheduleEventComponent } from './schedule/components/schedule-event/schedule-event.component';
import { SCHEDULE_ACTIVITY_TAG, ScheduleActivityTemplateComponent } from './schedule/components/schedule-activity-template/schedule-activity-template.component';
import { SCHEDULE_ACTIVITY_EVENT_TAG, ScheduleActivityEventComponent } from './schedule/components/schedule-activity-event/schedule-activity-event.component';
import { ScheduleDetailDialogComponent } from './schedule/components/schedule-detail-dialog/schedule-detail-dialog.component';
import { MultiselectTreeComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree.component';
import { MultiselectTreePropagationDialogComponent } from 'src/app/shared/components/inputs/multiselect-tree/multiselect-tree-propagation-dialog/multiselect-tree-propagation-dialog.component';

@NgModule({ 
    declarations: [
        OverviewsComponent,
        CountersOverviewComponent,
        CountersMatrixComponent,
        CountersFiltersComponent,
        CountersFiltersHeaderComponent,
        CountersFilterSettingsComponent,
        CountersExportComponent,
        CountersExportFiltersSettingsComponent,
        ScheduleActivityEventComponent,
        ScheduleOverviewComponent,
        ScheduleFiltersComponent,
        ScheduleFiltersSettingsComponent,
        ScheduleCalendarComponent,
        ScheduleFiltersHeaderComponent,
        ScheduleEventComponent,
        ScheduleActivityTemplateComponent,
        ShowOptionsConfigurationDialogComponent,
        ScheduleDetailDialogComponent
    ], 
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        SharedModule,
        ReactiveFormsModule,
        BolNotificationModule,
        BolCalloutModule,
        BryntumSchedulerModule,
        OverviewsRoutingModule,
        MultiselectTreeComponent,
        MultiselectTreePropagationDialogComponent], providers: [
        DecimalPipe,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class OverviewsModule { 
    constructor(injector: Injector) {
        const defineCustomElementIfNotDefined = (tagName: string, component: Type<any>): void => {
            if (!customElements.get(tagName)) {
                customElements.define(tagName, createCustomElement(component, { injector }));
            }
        };

        defineCustomElementIfNotDefined(SCHEDULE_EVENT_TAG, ScheduleEventComponent);
        defineCustomElementIfNotDefined(SCHEDULE_ACTIVITY_TAG, ScheduleActivityTemplateComponent);
        defineCustomElementIfNotDefined(SCHEDULE_ACTIVITY_EVENT_TAG, ScheduleActivityEventComponent);
    }
}
