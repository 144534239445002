@if ((isLoading$ | async) === false) {
    <div class="bol-modal__header">
        <span class="modal-title">{{ 'Activity details' | translate }}</span>
        <div
            class="bol-modal__close"
            (click)="onCloseModal()"
        >
            <mat-icon svgIcon="close"></mat-icon>
        </div>
    </div>
    <mat-dialog-content class="dialog">
        @if (showDescription) {
            <div class="activity-description">
                <span class="activity-description-text">{{ 'Description:' | translate }} </span>{{ description }}
            </div>
        }
        @if (allActivities.length > 0) {
            <div>
                <div class="activity-row activity-header">
                    <div
                        class="activity-time"
                        translate
                    >
                        Begin
                    </div>
                    <div
                        class="activity-time"
                        translate
                    >
                        End
                    </div>
                    <div
                        class="activity-short-name"
                        translate
                    >
                        Activity
                    </div>
                    <div
                        class="activity-detail-name"
                        translate
                    >
                        Display name
                    </div>
                    <div
                        class="activity-detail-name"
                        translate
                    >
                        Resource
                    </div>
                    <div
                        class="activity-detail-name"
                        translate
                    >
                        Organization unit
                    </div>
                </div>
                @for (activity of allActivities; track activity.id; let i = $index) {
                    <div
                        class="activity" 
                        [class.activity-root-line]="i === 0 && allActivities.length > 1"
                    >
                        <div class="activity-row">
                            <div class="activity-time">
                                {{ activity?.start }}
                            </div>
                            <div class="activity-time">
                                {{ activity?.end }}
                            </div>
                            <div class="activity-short-name">
                                <div
                                    class="activity-short-name-cell"
                                    [style.color]="'#' + activity?.activityType?.textColor"
                                    [style.background-color]="'#' + activity?.activityType?.backColor"
                                >
                                    {{ activity?.activityType?.shortName }}
                                </div>
                            </div>
                            <div class="activity-detail-name">{{ activity?.activityType?.displayName }}</div>
                            <div class="activity-detail-name">{{ activity?.resource }}</div>
                            <div class="activity-detail-name">{{ activity?.organizationUnit ?? '-' }}</div>
                        </div>
                        @if (activity.memo) {
                            <div class="activity-memo">
                                <div class="memo">
                                    <span class="activity-detail-name-memo-title">{{ 'Memo:' | translate }}</span>
                                    {{ activity.memo }}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        }
    </mat-dialog-content>
}

@if (isLoading$ | async) {
    <div class="spinner-container">
        <mat-spinner diameter="100"></mat-spinner>
    </div>
}
