import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { OwsInterfaceScheduling } from './ows-interface-scheduling.model';

export interface OwsInterfaceSchedulingState {
    ui: {
        dataLoaded: boolean;
        interfaceConfiguration?: {
            miscSettings: {
                daysInPast: number;
                daysInFuture: number;
            };
            instanceName: string;
        };
    };
}

const initialState = {
    ui: {
        dataLoaded: false,
    },
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ows-interface-configuration' })
export class OwsInterfaceSchedulingStore extends Store<OwsInterfaceSchedulingState> {
    constructor() {
        super(initialState);
    }

    public updateInterfaceConfiguration(newConfiguration: OwsInterfaceScheduling) {
        this.update((state) => {
            const ui = {
                ...state.ui,
                interfaceConfiguration: newConfiguration,
            };

            return { ...state, ui };
        });
    }

    public updateDataLoadedState(loaded: boolean) {
        this.update((state) => {
            const ui = {
                ...state.ui,
                dataLoaded: loaded,
            };

            return { ...state, ui };
        });
    }
}
